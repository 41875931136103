.login {
    position: relative;
    margin: 100px auto;
    padding-top: 100px;
    width: 400px;
    height: 280px;
    border-radius: 4px;
    /* background-color: rgba(255, 255, 255, 0.95); */
    /* box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%); */
}

.forget_password {
    height: 150px;
}

.login .logo {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.login #normal_login {
    margin: auto;
}

.login .login-form {
    max-width: 300px;
}

.login .login-form-forgot {
    float: right;
}

.login .login-form-register {
    float: right;
}

.login .login-form-button {
    width: 100%;
    margin-bottom: 1rem;
}