.Statistics {
    padding-left: 0;
}

.Statistics li {
    list-style: none;
    display: inline-block;
    margin: 0 10px 10px 0;
    padding: 10px 14px 10px 0;
}

.Statistics li h4 {
    margin-top: 0;
}

.Statistics li h4 span {
    font-weight: 400;
}