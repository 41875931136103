.article {
    margin: auto;
    width: 100%;
}

.content-m .article {
    width: 100%;
}

.article .article_content {
    float: left;
    margin-right: 1rem;
    width: 70%;
    border-radius: 4px;
}


.content-m .article .article_content {
    margin: auto;
    float: unset;
    width: 98%;
}

.article .card {
    width: 20%;
    padding: 1.66rem;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.article .card ul li {
    list-style: none;
}

.article #markdown-body {
    padding: 2rem;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background-color: #fff;
}

.article .markdown-body {
    overflow: hidden;
    width: 100%;
    min-height: 500px;
    background-color: #fff;
}

.article .comment {
    width: 93%;
    padding: 1.66rem;
    margin-top: 1.66rem;
    margin-right: 0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.article .comment_lists {
    margin-top: 1.66rem;
}

.article .comment_lists ul {
    width: 100%;
    padding-left: 0;
    margin-bottom: 2rem;
}

.article .comment_lists ul li {
    list-style: none;
}

#player-container-id {
    width: 100%;
    height: 400px;
}



.twemoji {
    width: 24px;
    height: 24px;
}