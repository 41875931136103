.nav {
    width: 100%;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.nav #nav {
    margin: auto;
    width: 65%;
}

.nav #nav-m {
    margin: auto;
    width: 98%;
}

.logo {
    float: left;
    height: 50px;
}


.logo img {
    padding-top: 10px;
    height: 50px;
}

.link {
    margin-top: 15px;
    margin-left: 10px;
    float: left;
}

.user_profile {
    float: right;
    padding-top: 15px;
    height: 45px;
}


.a666 {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: rgb(255, 240, 240);
}