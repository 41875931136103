.grid {
    position: relative;
    height: 230px;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
}

.grid .background_image {
    width: 100%;
    height: 100%;
}

.grid .title {
    position: absolute;
    width: 100%;
    bottom: 0;
    font-size: 18px;
    line-height: 22px;
    padding: 10px;
    color: #fff;
    background-color: rgba(0, 56, 0, 0.986);
}

.grid .title_vip {
    background-color: rgba(68, 2, 68, 0.986);
}

.grid .tag {
    position: absolute;
    right: 8px;
    bottom: 8px;
}

.grid .avatar {
    position: absolute;
    top: 10px;
    right: 10px;
}

.grid .like {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    background-color: #474747;
}

.grid .browse {
    position: absolute;
    top: 10px;
    left: 50px;
    color: #fff;
    background-color: #474747;
}

.grid .download {
    position: absolute;
    top: 10px;
    left: 90px;
    color: #fff;
    background-color: #474747;
}

.spin {
    width: 100%;
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 200px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}